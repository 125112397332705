// import type { AppProps } from 'next/app';
// import Head from 'next/head';
// import '@/app/globals.css';
// import Header from '@/components/Header';
// import Footer from '@/components/Footer';
// import { useEffect, useState } from 'react';
// import { UserLocationProvider } from '../context/UserLocationContext';
// import { LoadingProvider, useLoading } from '../context/LoadingContext';
// import Loading from "@/components/popup/Loading";
// import { Noto_Sans } from 'next/font/google';
// import CookieConsentPopup from '../components/CookieConsentPopup';
// // import ScrollToTopButton from '../components/ScrollToTopButton';
// import WhatsAppChat from "../components/WhatsAppChat"; // Import the WhatsApp component
// import GlobalInvisibleReCAPTCHA from '@/components/GlobalInvisibleReCAPTCHA';


// const noto_sans = Noto_Sans({
//   subsets: ['latin'],
//   weight: ['400'],
//   display: 'swap',
// });

// function AppContent({ Component, pageProps, router }: AppProps) {
//   const { isLoading, setIsLoading } = useLoading();

//   useEffect(() => {
//     const handleRouteChangeStart = () => setIsLoading(true);
//     const handleRouteChangeComplete = () => setIsLoading(false);

//     router.events.on('routeChangeStart', handleRouteChangeStart);
//     router.events.on('routeChangeComplete', handleRouteChangeComplete);
//     router.events.on('routeChangeError', handleRouteChangeComplete);

//     return () => {
//       router.events.off('routeChangeStart', handleRouteChangeStart);
//       router.events.off('routeChangeComplete', handleRouteChangeComplete);
//       router.events.off('routeChangeError', handleRouteChangeComplete);
//     };
//   }, [router.events, setIsLoading]);


//   const [captchaValue, setCaptchaValue] = useState<string | null>(null);

//   const handleCaptchaChange = (value: string | null) => {
//     setCaptchaValue(value);
//   };

//   const handleFormSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     if (!captchaValue) {
//       alert("Please complete the reCAPTCHA!");
//       return;
//     }

//     // Send captchaValue to your backend for verification
//     const response = await fetch('/api/verify-recaptcha', {
//       method: 'POST',
//       body: JSON.stringify({ captchaValue }),
//       headers: { 'Content-Type': 'application/json' },
//     });

//     const data = await response.json();
//     console.log(data.message); // Handle success or error
//   };


//   return (
//     <div className={noto_sans.className}>
//       {isLoading && <Loading />}
//       <UserLocationProvider>
//         <Header />
//         <div className="relative min-h-screen overflow-x-hidden">
//             <Component {...pageProps} />
//             <CookieConsentPopup />
//             <WhatsAppChat /> {/* WhatsApp chat button will be displayed */}
//             <GlobalInvisibleReCAPTCHA onChange={handleCaptchaChange} />
//             {/* <ScrollToTopButton /> */}
//         </div>
//         <Footer />
//       </UserLocationProvider>
//     </div>
//   );
// }

// function MyApp({ Component, pageProps, router }: AppProps) {
//   return (
//     <>
//       <Head>
//         <meta charSet="UTF-8" />
//         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//         <title>BizDataset</title>
//         <meta name="description" content="BizDataset" />
//       </Head>
//       <LoadingProvider>
//         <AppContent Component={Component} pageProps={pageProps} router={router} />
//       </LoadingProvider>
//     </>
//   );
// }

// export default MyApp;








//page view event seted code 

import type { AppProps } from 'next/app';
import Head from 'next/head';
import '@/app/globals.css';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { useEffect, useState, useCallback } from 'react';
import { UserLocationProvider } from '../context/UserLocationContext';
import { LoadingProvider, useLoading } from '../context/LoadingContext';
import Loading from "@/components/popup/Loading";
import { Noto_Sans } from 'next/font/google';
import CookieConsentPopup from '../components/CookieConsentPopup';
// import ScrollToTopButton from '../components/ScrollToTopButton';
import WhatsAppChat from "../components/WhatsAppChat"; // Import the WhatsApp component
import GlobalInvisibleReCAPTCHA from '@/components/GlobalInvisibleReCAPTCHA';
import { getFacebookData } from "@/utils/getFacebookData";


const noto_sans = Noto_Sans({
  subsets: ['latin'],
  weight: ['400'],
  display: 'swap',
});

function AppContent({ Component, pageProps, router }: AppProps) {
  const { isLoading, setIsLoading } = useLoading();


  const trackEvent = async (eventData: { event_name: string; [key: string]: any }) => {
    try {
      const trackEventResponse = await fetch('/api/conversions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
      });

      if (!trackEventResponse.ok) {
        const errorText = await trackEventResponse.text();
        console.error('Error sending event:', errorText);
      } else {
        const trackEventResult = await trackEventResponse.json();
        // Uncomment to debug or log the result
        // console.log('Event tracked successfully:', trackEventResult);
      }
    } catch (error) {
      console.error('Error sending event:', error);
    }
  };

  const getIpAddress = async () => {
    try {
      return '0.0.0.0';
        const response = await fetch('https://api.ipify.org?format=json&family=4');
        const data = await response.json();
        return data.ip; // Returns the client's IPv4 address
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return '0.0.0.0'; // Default to an invalid IP if the request fails
    }
};

  const handleRouteChange = useCallback(async (url: string) => {
    const currentPageUrl = window.location.href; // Get the current URL dynamically
    
    const eventData = {
      event_name: "PageView",
      event_time: Math.floor(Date.now() / 1000),
      event_id: Math.floor(Date.now() / 1000),
      user_data: {
        client_ip_address: await getIpAddress(),
        client_user_agent: navigator.userAgent,
        fbc: getFacebookData().fbc || "",
        fbp: getFacebookData().fbp || "",
        fb_login_id: getFacebookData().c_user,
      },
      custom_data: {},
      event_source_url: currentPageUrl,
      action_source: "website",
    };
  
    await trackEvent(eventData); // Trigger tracking event
  }, []); // Empty dependency array to ensure it is created once
  
  useEffect(() => {
    const handleRouteChangeStart = () => setIsLoading(true);
    const handleRouteChangeComplete = (url: string) => {
      setIsLoading(false);
      handleRouteChange(url); // Use the memoized version of handleRouteChange
    };
  
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);
  
    // Trigger PageView on initial load
    handleRouteChange(router.pathname);
  
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events, setIsLoading, handleRouteChange, router.pathname]);
  

  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!captchaValue) {
      alert("Please complete the reCAPTCHA!");
      return;
    }

    // Send captchaValue to your backend for verification
    const response = await fetch('/api/verify-recaptcha', {
      method: 'POST',
      body: JSON.stringify({ captchaValue }),
      headers: { 'Content-Type': 'application/json' },
    });

    const data = await response.json();
    console.log(data.message); // Handle success or error
  };


  return (
    <div className={noto_sans.className}>
      {isLoading && <Loading />}
      <UserLocationProvider>
        <Header />
        <div className="relative min-h-screen overflow-x-hidden">
            <Component {...pageProps} />
            <CookieConsentPopup />
            <WhatsAppChat /> {/* WhatsApp chat button will be displayed */}
            {/* <GlobalInvisibleReCAPTCHA onChange={handleCaptchaChange} /> */}
            {/* <ScrollToTopButton /> */}
        </div>
        <Footer />
      </UserLocationProvider>
    </div>
  );
}

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>BizDataset</title>
        <meta name="description" content="BizDataset" />
      </Head>
      <LoadingProvider>
        <AppContent Component={Component} pageProps={pageProps} router={router} />
      </LoadingProvider>
    </>
  );
}

export default MyApp;




// import type { AppProps } from 'next/app';
// import Head from 'next/head';
// import '@/app/globals.css';
// import Header from '@/components/Header';
// import Footer from '@/components/Footer';
// import { useEffect } from 'react';
// import { UserLocationProvider } from '../context/UserLocationContext';
// import { LoadingProvider, useLoading } from '../context/LoadingContext';
// import Loading from "@/components/popup/Loading";
// import { Noto_Sans } from 'next/font/google';
// import CookieConsentPopup from '../components/CookieConsentPopup';

// const noto_sans = Noto_Sans({
//   subsets: ['latin'],
//   weight: ['400'],
//   display: 'swap',
// });

// const scrollToTop = () => {
//   window.scrollTo({ top: 0, behavior: 'smooth' });
// };

// function AppContent({ Component, pageProps, router }: AppProps) {
//   const { isLoading, setIsLoading } = useLoading();

//   useEffect(() => {
//     const handleRouteChangeStart = () => {
//       setIsLoading(true);
//       scrollToTop();
//     };
//     const handleRouteChangeComplete = () => setIsLoading(false);
//     const handleRouteChangeError = () => setIsLoading(false);

//     router.events.on('routeChangeStart', handleRouteChangeStart);
//     router.events.on('routeChangeComplete', handleRouteChangeComplete);
//     router.events.on('routeChangeError', handleRouteChangeError);

//     return () => {
//       router.events.off('routeChangeStart', handleRouteChangeStart);
//       router.events.off('routeChangeComplete', handleRouteChangeComplete);
//       router.events.off('routeChangeError', handleRouteChangeError);
//     };
//   }, [router.events, setIsLoading]);

//   return (
//     <div className={noto_sans.className}>
//       {isLoading && <Loading />}
//       <UserLocationProvider>
//         <Header />
//         <div className="relative min-h-screen">
//           <Component {...pageProps} />
//           <CookieConsentPopup />
//         </div>
//         <Footer />
//       </UserLocationProvider>
//     </div>
//   );
// }

// function MyApp({ Component, pageProps, router }: AppProps) {
//   return (
//     <>
//       <Head>
//         <meta charSet="UTF-8" />
//         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//         <title>BizDataset</title>
//         <meta name="description" content="BizDataset" />
//       </Head>
//       <LoadingProvider>
//         <AppContent Component={Component} pageProps={pageProps} router={router} />
//       </LoadingProvider>
//     </>
//   );
// }

// export default MyApp;




// import type { AppProps } from 'next/app';
// import Head from 'next/head';
// import '@/app/globals.css';
// import Header from '@/components/Header';
// import Footer from '@/components/Footer';
// import { useEffect } from 'react';
// import { UserLocationProvider } from '../context/UserLocationContext';
// import { LoadingProvider, useLoading } from '../context/LoadingContext';
// import Loading from "@/components/popup/Loading";
// import { Noto_Sans } from 'next/font/google';
// import CookieConsentPopup from '../components/CookieConsentPopup';

// const noto_sans = Noto_Sans({
//   subsets: ['latin'],
//   weight: ['400'],
//   display: 'swap',
// });

// function AppContent({ Component, pageProps, router }: AppProps) {
//   const { isLoading, setIsLoading } = useLoading();

//   useEffect(() => {
//     const handleRouteChangeStart = () => {
//       setIsLoading(true); // Start loading when route change starts
//       setTimeout(() => setIsLoading(false), 0); // Turn off loading immediately
//     };
    
//     router.events.on('routeChangeStart', handleRouteChangeStart);

//     return () => {
//       router.events.off('routeChangeStart', handleRouteChangeStart);
//     };
//   }, [router.events, setIsLoading]);

//   return (
//     <div className={noto_sans.className}>
//       {isLoading && <Loading />}
//       <UserLocationProvider>
//         <Header />
//         <div className="relative min-h-screen">
//             <Component {...pageProps} />
//           <CookieConsentPopup />
//         </div>
//         <Footer />
//       </UserLocationProvider>
//     </div>
//   );
// }

// function MyApp({ Component, pageProps, router }: AppProps) {
//   return (
//     <>
//       <Head>
//         <meta charSet="UTF-8" />
//         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//         <title>BizDataset</title>
//         <meta name="description" content="BizDataset" />
//       </Head>
//       <LoadingProvider>
//         <AppContent Component={Component} pageProps={pageProps} router={router} />
//       </LoadingProvider>
//     </>
//   );
// }

// export default MyApp;

