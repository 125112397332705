export const trackEvent = async (eventData: Record<string, any>) => {
  try {
    const trackEventResponse = fetch('/api/conversions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    });

    // if (!trackEventResponse.ok) {
    //   const errorText = await trackEventResponse.text();
    //   console.error('Error sending event:', errorText);
    // } else {
    //   const trackEventResult = await trackEventResponse.json();
    //   console.log('Event tracked successfully:', trackEventResult);
    // }
  } catch (error) {
    console.error('Error in tracking event:', error);
  }
};
